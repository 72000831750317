import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();
});

jQuery(document).ready(function ($) {
// COOKIES ++++++++++++++++++++++++++ //

// melding-popup
$('.melding-popup .sluiten').click(function() { $('.melding-popup').addClass('hidden');
Cookies.set('melding-popup.hide','true', { expires: 7 });});
if (Cookies.get('melding-popup.hide')) {$('.melding-popup').addClass('keep-hidden');}

// melding-boven
$('.melding-boven .sluiten').click(function() { $('.melding-boven').addClass('hidden');$('.site-header').addClass('normal');
Cookies.set('melding-boven.hide','true', {element: '.melding-boven'}, { expires: 7 });});
if (Cookies.get('melding-boven.hide')) {$('.melding-boven').addClass('keep-hidden');$('.site-header').addClass('normal');}
});

function checkPosition() {
	let windowY = window.scrollY;
	if (windowY > 200) {
		document.body.classList.add('is-scroll');
	} else {
		document.body.classList.remove('is-scroll');
	}
}

function debounce(func, wait = 10, immediate = true) {
	let timeout;
	return function () {
		let context = this,
			args = arguments;
		let later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

window.addEventListener('scroll', debounce(checkPosition));

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// TABS
//------------------------------------//
const tabTriggers = document.querySelectorAll('.tab-title');
const tabs = document.querySelectorAll('[data-section="steps"] .tab');
const nextTab = document.querySelector('.tab-nav[data-dir="next"]');
const prevTab = document.querySelector('.tab-nav[data-dir="prev"]');
let currentTab = 1;

[...tabTriggers].forEach((trigger) => {
	//trigger.addEventListener('click', switchTab);
	trigger.addEventListener('click', (e) => {
		e.preventDefault();
		let targetTab = parseInt(e.currentTarget.parentNode.dataset.tab);
		switchTab(targetTab);
	});
});

function switchTab(target) {
	currentTab = target;

	removeActive('.tabs .img-block[data-active]');
	removeActive('.tab[data-active]');

	document.querySelector(
		`.tab[data-tab='${currentTab}']`
	).dataset.active = true;
	document.querySelector(
		`.img-block[data-tab='${currentTab}']`
	).dataset.active = true;
}

nextTab &&
	nextTab.addEventListener('click', (e) => {
		if (currentTab < tabs.length) {
			let targetTab = currentTab + 1;
			switchTab(targetTab);
		}
	});

prevTab &&
	prevTab.addEventListener('click', (e) => {
		if (currentTab > 1) {
			let targetTab = currentTab - 1;
			switchTab(targetTab);
		}
	});

//------------------------------------//
// TABS - SELECT
//------------------------------------//
const inputTrigger = document.querySelector('.tabs-nav select');

inputTrigger &&
	inputTrigger.addEventListener('change', (e) => {
		e.preventDefault();

		let elTarget = e.currentTarget.value;

		removeActive('.tab[data-active]');

		//trigger.dataset.active = true;
		document.querySelector(
			`.tab[data-tab='${elTarget}']`
		).dataset.active = true;
	});

//------------------------------------//
// ACCORDION
//------------------------------------//
const accordionTriggers = document.querySelectorAll(
	'[data-list="accordion"] .item-trigger'
);

[...accordionTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchAccordion);
});

function switchAccordion(e) {
	e.preventDefault();

	removeActive('[data-list="accordion"] .item');
	e.currentTarget.parentNode.dataset.active = 'true';
}
